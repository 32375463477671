<template>
  <div class="about-wrapper">
    <div class="banner">
      <el-image :src="bannerUrl">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>图片正在加载
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <div class="about-content">
      <p class="zh-title">关于我们</p>
      <p class="en-title">ABOUT US</p>
      <div class="about-text">
        我们成立于2021年，<br/>
        是一家集招聘、医疗专业技术能力培训、上岗考核于一体的管理咨询有限公司，<br/>
        致力于为医疗企业提供一站式的人才孵化及培养服务，帮助企业树立人才竞争优势，提升经营成效。
      </div>
      <div class="flex">
        <div class="sub-banner">
          <el-image :src="subBanner">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="sub-banner">
          <img src="../../assets/images/img_assessment.jpg" />
        </div>
      </div>
    </div>
    <div>
      <p class="zh-title">企业战略</p>
      <p class="en-title">ENTERPRISE STRATEGY</p>
      <div class="flex">
        <div class="strategy-item">
          <img src="../../assets/images/icon/mission@2x.png" alt="">
          <p class="strategy-item-title">使命</p>
          <p class="strategy-item-text">做优秀医疗行业的输送者</p>
        </div>
        <div class="strategy-item">
          <img src="../../assets/images/icon/vision@2x.png" alt="">
          <p class="strategy-item-title">愿景</p>
          <p class="strategy-item-text">坚持“专业赋能”价值观<br>共同推进医疗行业人才的专业化管理</p>
        </div>
        <div class="strategy-item">
          <img src="../../assets/images/icon/culture@2x.png" alt="">
          <p class="strategy-item-title">我们的文化</p>
          <p class="strategy-item-text">高效、专业、创新</p>
        </div>
      </div>
    </div>
    <div class="service">
      <p class="zh-title">服务理念</p>
      <p class="en-title">SERVICE IDEA</p>
      <div>
        在新一轮产业变革加速推进、新业态新模式不断涌现的背景下，我们迎合大时代浪潮，始终铭记“高效、专业、创新”的价值观，<br>
        提供一站式运营服务，通过多样化的人才服务，帮助组织更好地发现、发展和发挥人才，<br>
        为企业及领导者、职场人搭建可持续的战略性思维，帮助企业在一定范围内获取人才竞争优势，提升组织效能，为医疗企业人才配置与业务发展提供一体化支撑。
      </div>
    </div>
    <div class="img-box sub-banner">
      <el-image :src="imgWall">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import {getBanner} from './request'

export default {
  data() {
    return {
      bannerUrl: '',
      subBanner: '',
      imgWall: '', //底部图片墙
    }
  },
  mounted() {
    this.fetchImgUrl()
  },
  methods: {
    async fetchImgUrl() {
      let res = await getBanner({
        type: 'about',
        provider: 'about'
      })
      if(res.data.data){
        const imgUrl = JSON.parse(res.data.data)
        this.bannerUrl = imgUrl.aboutBanner
        this.subBanner = imgUrl.introImg
        this.imgWall = imgUrl.imgWall
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  margin-bottom: 40px;
  img{
    width: 100%;
  }
}
.about-content{
  text-align: center;
  font-size: 16px;
  color: #333;
  padding: 0 10%;
  margin-bottom: 30px;

  .sub-banner{
    width: 50%;
    img{
      width: 100%;
    }
  }
}
.zh-title{
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 8.33px;
  text-align: center;
}
.en-title{
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 40px;
}
.about-text{
  margin: 0 auto;
  line-height: 30px;
  margin-bottom: 30px;
}

.strategy-item{
  text-align: center;
  width: 33%;
  img{
    width: 80px;
  }

  .strategy-item-title{
    font-size: 24px;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .strategy-item-text{
    font-size: 16px;
    color: #999999;
  }
}
.service{
  margin-top: 50px;
  text-align: center;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
}
.img-box{
  margin: 30px 0;
  padding: 0 10%;
}
</style>